import {
  ASSESSMENT_RESPONSES_SEARCH_LOADING,
  ASSESSMENT_RESPONSES_SEARCH_SUCCESS,
  ASSESSMENT_RESPONSES_SEARCH_FAILURE,
  SET_ASSESSMENT_RESPONSES,
  SEARCH_ASSESSMENT_LOADING,
  SEARCH_ASSESSMENT_SUCCESS,
  SEARCH_ASSESSMENT_FAILURE,
} from 'actions/assessmentResponses';
import { convertToLocalDateTime } from 'components/Common/TimeFunctions';
import { ADEQUATE, SINGLE_SELECT_RESPONSE_ID, MULTI_SELECT_RESPONSE_ID } from 'constants/constants';
import { isNil, omit, isEmpty } from 'lodash';
import { getDisplayControlName } from 'utils/stringUtils';

const initialState = {
  loading: false,
  assessmentResponses: [],
  filteredAssessmentResponses: [],
  assessmentData: {},
};


export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ASSESSMENT_RESPONSES_SEARCH_LOADING:
    case SEARCH_ASSESSMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case ASSESSMENT_RESPONSES_SEARCH_SUCCESS: {
      const omittedResponseData = payload?.Data?.Rows?.map((item) => {
        return omit(item, 'KeyControlId', 'Outcome');
      });      
      // Grouping the objects based on "ControlName" using Array.reduce
      const groupedArray = omittedResponseData?.reduce((acc, obj) => {
        const key = getDisplayControlName(obj);
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});

      // Creating an array of objects with ControlName as keys and nested arrays as questionList
      const resultArray = Object.entries(groupedArray).map(([key, value]) => {
        let questions = [];
        // Map the value prop for parent questions
        let parentQuestions = value.map((question) => {
          return {
            ...question,
            IsMet: isNil(question?.Outcome_Value) || question?.Outcome_Value === 'N/A' || question?.Outcome_Value === 'Not Applicable' ? null : question?.Outcome_Value?.toLowerCase() === ADEQUATE,
            Id: question.AssessmentQuestionnaireQuestionResponseId,
            LastUpdated: !isNil(question.LastUpdated) && convertToLocalDateTime(question.LastUpdated, 'MM/dd/yyyy'),
          };
        })
        // Map the value prop for child questions
        if (parentQuestions.length > 0) {
          parentQuestions.forEach((question) => {
            let childQuestions = [];
            let selectedResponseOptions = [];
            questions.push(question);
            if (question.QuestionResponseTypeId	=== SINGLE_SELECT_RESPONSE_ID) {
              childQuestions = question.ResponseOptionList?.filter((childResponse) => childResponse?.ResponseOptionText === question.QuestionResponseText)[0]?.QuestionLogicRuleList[0]
                ?.ChildQuestionList;
            }
            else if (question.QuestionResponseTypeId	=== MULTI_SELECT_RESPONSE_ID) {
              selectedResponseOptions = question?.ResponseOptionList?.filter((childResponse) => question?.QuestionResponseText.includes(childResponse?.ResponseOptionText));
              selectedResponseOptions.forEach((responseOption) => {
                childQuestions = childQuestions.concat(responseOption?.QuestionLogicRuleList[0]?.ChildQuestionList);
              })
            }
            if (!isEmpty(childQuestions)) {
              childQuestions = childQuestions.map((childQuestion) => {
                return {
                  ...childQuestion,
                  VendorResponse: childQuestion?.QuestionResponseText,
                  Comments: childQuestion?.QuestionResponseComment,
                  EvidenceProvided: !isEmpty(childQuestion?.ResponseAttachmentList),
                  IsMet: isNil(childQuestion?.AdequacyName) || childQuestion?.AdequacyName === 'N/A' || childQuestion?.AdequacyName === 'Not Applicable' ? null : childQuestion?.AdequacyName?.toLowerCase() === ADEQUATE,
                  Id: childQuestion.AssessmentQuestionnaireQuestionResponseId,
                  LastUpdated: !isNil(childQuestion.UpdateTimestamp) && convertToLocalDateTime(childQuestion.UpdateTimestamp, 'MM/dd/yyyy'),
                }
              });
              questions = questions.concat(childQuestions);
            }
          });
        }
        return {
          ControlName: key,
          QuestionList: questions,
        };
      });
      const uniqueRecordsMap = new Map();
      // Loop through resultArray to find unique records based on ControlName
      resultArray.forEach(obj => {
        obj.QuestionList.forEach(item => {
          const controlName = item.ControlName;
          if (!uniqueRecordsMap.has(controlName)) {
            uniqueRecordsMap.set(controlName, item);
          }
        });
      });

      return {
        ...state,
        loading: false,
        assessmentResponses: resultArray,
        originalAssessmentResponses: resultArray.flatMap((response) => response.QuestionList),
        uniqueRecords : [...uniqueRecordsMap.values()],
      };
    }

    case ASSESSMENT_RESPONSES_SEARCH_FAILURE:
    case SEARCH_ASSESSMENT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_ASSESSMENT_RESPONSES: {
      return {
        ...state,
        filteredAssessmentResponses: payload,
      };
    }

    case SEARCH_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        assessmentData: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0],
      };
    }

    default:
      return state;
  }
}
